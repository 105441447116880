import * as React from 'react'
import { PageProps, graphql } from 'gatsby'
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image'

import Layout from '../components/layout/layout'
import Seo from '../components/layout/seo'

type TaxProps = {
	file: {
		childImageSharp: ImageDataLike
	}
}
const TaxDocument: React.FC<PageProps<TaxProps>> = ({ data: { file: { childImageSharp } } }) => {
	const gatsbyimage = getImage(childImageSharp)
	return (
		<Layout>
			<Seo title="Adó 1% rendelkező nyilatkozat" />
			<div className="my-6 p-8 bg-white">
                <article className="prose md:prose-sm max-w-none
                    prose-h1:text-[#077ab3] prose-h1:uppercase prose-h1:font-bold prose-h2:text-[#87c2e1]
                    prose-h3:text-base md:prose-h3:text-base prose-p:text-justify
                    prose-a:text-[#0088cc] prose-a:font-bold prose-a:underline-offset-2 hover:prose-a:text-[#005580]">
                    <h1>Adó 1% rendelkező nyilatkozat</h1>
					<div className="w-full max-w-[576px] mx-auto my-4 text-center not-prose">
						<a
							className="block border-2 border-transparent hover:border-blue-300"
							aria-label="Adó 1% rendelkező nyilatkozat megnyitása"
							href={ '/letoltes/rendelkezo_nyilatkozat.pdf' }
							target="_blank"
							rel="noreferrer"
						>
							{ gatsbyimage && (<GatsbyImage
								alt=""
								image={ gatsbyimage }
							/>) }
						</a>
						<a
							className="inline-block w-auto my-4 p-4
								rounded-full bg-[#005580] hover:bg-[#0088cc] text-white font-bold"
							aria-label="Adó 1% rendelkező nyilatkozat letöltése"
							href={ '/letoltes/rendelkezo_nyilatkozat.pdf' }
							download="rendelkezo_nyilatkozat.pdf"
						>
							A nyilatkozat letöltése
						</a>
					</div>
                </article>
            </div>
		</Layout>
)}

export default TaxDocument

export const query = graphql`
	query {
		file(relativePath: { eq: "rendelkezo_nyilatkozat.jpg" }) {
			childImageSharp {
				gatsbyImageData(
					placeholder: TRACED_SVG
					layout: FULL_WIDTH
					breakpoints: [576, 977]
				)
			}
		}
	}
`
